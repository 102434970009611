import { useContext } from "react";
import { Link } from "react-router-dom";
import { DropdownMenuMobileContext } from "../../context/DropdownMenuMobileContext";
import { TranslateLabel } from "../../labels/TranslateLabel";

/**
 * Renders a button than closes the mobile menu after being used
 * @returns React element
 */
export function BlueButton(props) {
  const { mobileMenuState } = useContext(DropdownMenuMobileContext);

  const classes =
    "animate-bounce transition duration-700 ease-in-out flex items-center justify-center text-sm rounded px-4 py-2.5 font-semibold bg-gray-800 text-white hover:bg-[#e2d705] hover:text-gray-800 min-w-52";
  return (
    <Link
      to={props.to}
      key={`blue-button-${props.label}`}
      className={classes}
      onClick={() => {
        if (mobileMenuState.close) {
          mobileMenuState.close();
        }
      }}
    >
      {TranslateLabel(props.label)}
    </Link>
  );
}
