import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import isNil from "lodash.isnil";
import { useContext, useEffect } from "react";
import { Link, Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { classNames } from "../../Utils";
import { DropdownMenuMobileContext } from "../../context/DropdownMenuMobileContext";
import { TranslateLabel } from "../../labels/TranslateLabel";
import DropdownMenuMobile from "./DropdownMenuMobile";
import HeaderMenuData from "./HeaderMenuData";
import { LanguageToggle } from "./LanguageToggle";
import { Logo } from "./Logo";

function NavigationItem(props) {
  const translatedLabel = TranslateLabel(props.item.label);

  return (
    <Link
      to={props.item.href}
      key={props.item.label}
      className={classNames(
        props.isCurrent
          ? "bg-gray-700 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "rounded-md px-3 py-2 text-lg font-medium capitalize"
      )}
      aria-current={props.isCurrent ? "page" : undefined}
    >
      {translatedLabel}
    </Link>
  );
}

function Navigation() {
  const location = useLocation();
  return (
    <div className="flex space-x-4">
      {HeaderMenuData.map((item) => {
        return (
          <NavigationItem
            key={item.label}
            isCurrent={location.pathname === item.href}
            item={item}
          />
        );
      })}
    </div>
  );
}

function MobileMenuButton(props) {
  return (
    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
      <span className="absolute -inset-0.5" />
      <span className="sr-only">Open main menu</span>
      {props.open ? (
        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  );
}

function DisclosureInner(props) {
  const { mobileMenuState, setMobileMenuState } = useContext(
    DropdownMenuMobileContext
  );

  useEffect(() => {
    if (mobileMenuState.open != props.open || isNil(mobileMenuState.close)) {
      setMobileMenuState({ open: props.open, close: props.close });
    }
  }, []);

  return (
    <>
      <div className="mx-auto max-w-7xl px-3 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
            <MobileMenuButton open={props.open} />
          </div>
          <div className="flex inset-y-0 left-0 sm:items-stretch sm:justify-start">
            <Logo close={props.close} />
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="hidden sm:ml-6 sm:block">
              <Navigation />
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <LanguageToggle />
            </div>
          </div>
        </div>
      </div>
      <Disclosure.Panel className="sm:hidden">
        <DropdownMenuMobile open={props.open} close={props.close} />
      </Disclosure.Panel>
    </>
  );
}

export function Header() {
  return (
    <>
      <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
        {({ open, close }) => <DisclosureInner open={open} close={close} />}
      </Disclosure>
      <Outlet />
      <ScrollRestoration />
    </>
  );
}
