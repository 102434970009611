import React from "react";
import { FaEnvelope } from "react-icons/fa6";
import { Link } from "react-router-dom";
import config from "../../config";

const ButtonMailTo = ({
  label,
  subject,
  body,
  className = "bg-gray-800 special-yellow py-2 px-4 rounded-xl hover:bg-gray-700 text-xl font-medium",
}) => {
  let mailToUrl = new URL(`mailto:${config.email}`);
  mailToUrl.searchParams.append("subject", subject);
  mailToUrl.searchParams.append("body", body);
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailToUrl.href;
        e.preventDefault();
      }}
      className={className}
    >
      <FaEnvelope />
      <span className="pl-2">{label}</span>
    </Link>
  );
};

export default ButtonMailTo;
