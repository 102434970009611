import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "../../Utils";
import {
  LanguageContext,
  getFlagByLanguage,
  nextLanguage,
} from "../../context/LanguageContext";
import { TranslateLabel } from "../../labels/TranslateLabel";
import HeaderMenuData from "./HeaderMenuData";

function MobileLanguageSelector(props) {
  const { language, setLanguage } = useContext(LanguageContext);
  return (
    <div className="flex justify-center py-2 px-2">
      <Link
        to={"/"}
        className={classNames(
          "bg-gray-800 text-white",
          "rounded-md text-2xl font-medium capitalize"
        )}
        onClick={async () => {
          const aux = nextLanguage(language);
          setLanguage(aux);
          props.close();
        }}
      >
        {getFlagByLanguage(language)}
      </Link>
    </div>
  );
}

function DropdownMenuMobileItem(props) {
  const translatedLabel = TranslateLabel(props.item.label);

  return (
    <Link
      to={props.item.href}
      className={classNames(
        props.isCurrent
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "block rounded-md px-2 py-1 text-lg font-medium text-center capitalize"
      )}
      aria-current={props.isCurrent ? "page" : undefined}
      onClick={async () => {
        props.close();
      }}
    >
      {translatedLabel}
    </Link>
  );
}

export default function DropdownMenuMobile(props) {
  const location = useLocation();
  return (
    <>
      {props.open && (
        <div className="space-y-1 px-2 py-1">
          {HeaderMenuData.map((item) => {
            return (
              <DropdownMenuMobileItem
                key={item.label}
                item={item}
                isCurrent={location.pathname === item.href}
                close={props.close}
              />
            );
          })}
          <MobileLanguageSelector
            key={"mobile-language-selector"}
            close={props.close}
          />
        </div>
      )}
    </>
  );
}
