import { BlueButton } from "../../components/blue-button/BlueButton";
import { TranslateLabel } from "../../labels/TranslateLabel";

export default function NoPage() {
  return (
    <div className="grid grid-cols-1 my-40 sm:my-60">
      <div className="flex justify-center py-2 px-2">
        <h1 className="special-yellow text-7xl md:text-9xl font-bold">404</h1>
      </div>
      <div className="flex justify-center pb-2 px-2">
        <h1 className="text-white text-2xl md:text-3xl lg:text-4xl">
          {TranslateLabel("This page does not exist")}!
        </h1>
      </div>
      <div className="flex justify-center py-1 px-2">
        <BlueButton label={TranslateLabel("Back home")} to={"/"} />
      </div>
    </div>
  );
}
