import { useContext } from "react";
import {
  LanguageContext,
  getFlagByLanguage,
  nextLanguage,
} from "../../context/LanguageContext";

export function LanguageToggle() {
  const { language, setLanguage } = useContext(LanguageContext);
  return (
    <div
      onClick={() => {
        const aux = nextLanguage(language);
        setLanguage(aux);
      }}
      className="text-2xl font-medium text-center hover:animate-bounce"
    >
      {getFlagByLanguage(language)}
    </div>
  );
}
