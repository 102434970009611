const LABELS = {
  Address: {
    fr: "Adresse",
    pt: "Endereço",
  },
  "All rights reserved": {
    fr: "Tous Droits Réservés",
    pt: "Todos os direitos reservados",
  },
  Analysis: {
    fr: "Analyse",
    pt: "Análise",
  },
  and: {
    fr: "et",
    pt: "e",
  },
  "API development": {
    fr: "Développement d'API",
    pt: "Desenvolvimento de APIs",
  },
  apps: {
    fr: "applications",
    pt: "aplicações",
  },
  "Back home": {
    fr: "Retour à l'accueil",
    pt: "Voltar ao início",
  },
  contact: {
    fr: "contact",
    pt: "contactos",
  },
  Crafting: {
    fr: "Développement d'applications",
    pt: "Desenvolvimento de apps",
  },
  "Custom made": {
    fr: "Sur mesure",
    pt: "Personalizado",
  },
  Design: {
    fr: "Conception",
    pt: "Design",
  },
  "Develop a comprehensive blueprint for the system or product, detailing its structure, features, and functionality. Focus on scalability, usability, and user experience.":
    {
      fr: "Élaborer un plan détaillé pour le système ou le produit, décrivant sa structure, ses fonctionnalités et sa convivialité. Mettre l'accent sur la scalabilité, l'utilisabilité et l'expérience utilisateur.",
      pt: "Desenvolver um plano abrangente para o sistema ou produto, detalhando sua estrutura, características e funcionalidade. Focar em escalabilidade, usabilidade e experiência do usuário.",
    },
  Development: {
    fr: "Développement",
    pt: "Desenvolvimento",
  },
  Email: {
    fr: "Email",
    pt: "Email",
  },
  "Evaluate the system's functionality, detecting and addressing issues to ensure a robust and error-free product. Conduct thorough testing, including unit, integration, and user acceptance testing.":
    {
      fr: "Évaluer la fonctionnalité du système, détecter et résoudre les problèmes pour garantir un produit robuste et sans erreur. Effectuer des tests approfondis, y compris des tests unitaires, d'intégration et d'acceptation par l'utilisateur.",
      pt: "Avaliar a funcionalidade do sistema, detectar e resolver problemas para garantir um produto robusto e sem erros. Realizar testes abrangentes, incluindo testes unitários, de integração e de aceitação do usuário.",
    },
  "Gather requirements and define project scope. Risks, constraints, and user needs are identified. It establishes the foundation for successful project execution.":
    {
      fr: "Évaluation des besoins et définition de la portée du projet. Les risques, les contraintes et les besoins des utilisateurs sont identifiés. Cela établit les bases d'une exécution réussie du projet.",
      pt: "Levantamento de requisitos e definição do âmbito do projecto. Riscos, restrições e necessidades do utilizador são identificados. Estabelece a base para a execução bem-sucedida do projecto.",
    },
  "Get a free quote for your new project": {
    fr: "Obtenez un devis gratuit pour votre nouveau projet",
    pt: "Obtenha um orçamento gratuito para o seu novo projeto",
  },
  "Get In Touch": {
    fr: "Entrer en contact",
    pt: "Entrar em contato",
  },
  "Have a specific inquiry or want to know more": {
    fr: "Vous avez une demande spécifique ou souhaitez en savoir plus",
    pt: "Tem alguma dúvida específica ou quer saber mais",
  },
  home: {
    fr: "accueil",
    pt: "inicio",
  },
  Implementation: { fr: "Implémentation", pt: "Implementação" },
  Maintenance: {
    fr: "Maintenance",
    pt: "Manutenção",
  },
  "Mobile first": {
    fr: "Mobile first",
    pt: "Mobile first",
  },
  Name: {
    fr: "Nom",
    pt: "Nome",
  },
  "Phone number": {
    fr: "Numéro de téléphone",
    pt: "Número de telefone",
  },
  Planning: {
    fr: "Planification",
    pt: "Planeamento",
  },
  portfolio: {
    fr: "portefeuille",
    pt: "portfólio",
  },
  "Release the product to users and provide ongoing support, addressing issues, and implementing updates for continued optimal performance. Monitor system health and user feedback for continuous improvement.":
    {
      fr: "Publier le produit auprès des utilisateurs et fournir un support continu, résoudre les problèmes et mettre en œuvre des mises à jour pour une performance optimale continue. Surveiller la santé du système et les retours des utilisateurs pour une amélioration continue.",
      pt: "Lançar o produto para os usuários e fornecer suporte contínuo, abordando problemas e implementando atualizações para uma performance ótima contínua. Monitorar a saúde do sistema e o feedback dos usuários para melhorias contínuas.",
    },
  "Send email": {
    fr: "Envoyer un email",
    pt: "Enviar email",
  },
  "Send us a message": {
    fr: "Envoyez-nous un message",
    pt: "Envie-nos uma mensagem",
  },
  "Strategically outline goals, allocate resources, and set timelines to create an efficient roadmap for project execution. Factor in risks and contingencies.":
    {
      fr: "Définir stratégiquement les objectifs, allouer les ressources et fixer les échéances pour créer une feuille de route efficace pour l'exécution du projet. Prendre en compte les risques et les contingences.",
      pt: "Definir estrategicamente metas, alocar recursos e estabelecer cronogramas para criar uma estratégia eficiente de execução do projeto. Levar em consideração riscos e contingências.",
    },
  Testing: {
    fr: "Test",
    pt: "Teste",
  },
  "Testing & integration": {
    fr: "Tests et intégration",
    pt: "Testes & integração",
  },
  "This page does not exist": {
    fr: "Cette page n'existe pas",
    pt: "Esta página não existe",
  },
  "Transform the design into a functional system, writing code, and integrating components to bring the project to life. Ensure adherence to coding standards and best practices.":
    {
      fr: "Transformer la conception en un système fonctionnel, écrire du code et intégrer des composants pour donner vie au projet. Veiller au respect des normes de codage et des bonnes pratiques.",
      pt: "Transformar o design em um sistema funcional, escrevendo código e integrando componentes para dar vida ao projeto. Garantir conformidade com padrões de codificação e melhores práticas.",
    },
  "Website development": {
    fr: "Développement de sites Web",
    pt: "Desenvolvimento de websites",
  },
  "Write a message here": {
    fr: "Écrivez un message ici",
    pt: "Escreva uma mensagem aqui",
  },
};
export default LABELS;
