import React from "react";

const srcUrl =
  "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d357.16844780838227!2d-8.820499327094415!3d39.60128225776529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPorto%20de%20M%C3%B3s!5e0!3m2!1spt-PT!2sie!4v1706139335628!5m2!1spt-PT!2sie";

export default function Map() {
  const containerClasses = "spinner h-full";

  return (
    <div className={containerClasses}>
      <iframe
        id="contact-map"
        title="contact-map"
        src={`${srcUrl}`}
        className="left-0 top-0 h-full w-full rounded-xl md:rounded-2xl"
      ></iframe>
    </div>
  );
}
