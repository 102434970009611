import { useState } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Footer from "./components/footer/Footer";
import { Header } from "./components/header/Header";
import { DropdownMenuMobileContext } from "./context/DropdownMenuMobileContext";
import { LANGUAGES, LanguageContext } from "./context/LanguageContext";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import NoPage from "./pages/nopage/NoPage";
import Portfolio from "./pages/portfolio/Portfolio";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Header />}>
      <Route index element={<Home />} />
      <Route path="portfolio" element={<Portfolio />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<NoPage />} />
    </Route>
  )
);

function App() {
  const [language, setLanguage] = useState(LANGUAGES.en);
  const [mobileMenuState, setMobileMenuState] = useState(LANGUAGES.en);
  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <DropdownMenuMobileContext.Provider
        value={{ mobileMenuState, setMobileMenuState }}
      >
        <div className="flex flex-col min-h-screen bg-gradient-to-br to-sky-700 from-gray-800">
          <main className="flex-1">
            <RouterProvider router={router} />
          </main>
          <Footer />
        </div>
      </DropdownMenuMobileContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;
