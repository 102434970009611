import { useContext } from "react";
import {
  FaBinoculars,
  FaBrain,
  FaCodeMerge,
  FaCubes,
  FaEarthEurope,
  FaHammer,
  FaHandshake,
  FaMobileScreenButton,
  FaRegPaste,
  FaRocket,
} from "react-icons/fa6";
import { BlueButton } from "../../components/blue-button/BlueButton";
import { LANGUAGES, LanguageContext } from "../../context/LanguageContext";
import { IsLanguageEnglish, TranslateLabel } from "../../labels/TranslateLabel";

function DevelopmentDetail({ icon, label }) {
  return (
    <>
      <div className="hover:scale-125 ease-in duration-500 text-4xl md:text-6xl lg:text-8xl m-auto mb-2">
        {icon}
      </div>
      <div>
        <p>{TranslateLabel(label)}</p>
      </div>
    </>
  );
}

function DevelopmentStep(props) {
  return (
    <div className="grid grid-col-1 my-3 px-2 sm:px-3">
      <div className="hover:scale-125 ease-in duration-500 m-auto text-6xl">
        {props.icon}
      </div>
      <div className="m-auto">
        <h3 className="text-center text-lg font-semibold my-1">
          {props.title}
        </h3>
        <p className="text-justify text-sm text-gray-300 align-text-top">
          {props.description}
        </p>
      </div>
    </div>
  );
}

function DevelopmentProcessTitle() {
  const { language } = useContext(LanguageContext);
  switch (language) {
    case LANGUAGES.fr:
      return (
        <>
          Processus de <span>design</span> et de <span>développement</span>
        </>
      );
    case LANGUAGES.pt:
      return (
        <>
          Processo de <span>design</span> e <span>desenvolvimento</span>
        </>
      );
    default:
      return (
        <>
          <span>Design</span> and <span>development</span> process
        </>
      );
  }
}

export default function Home() {
  return (
    <div className="bg-gray-800 text-white">
      <section className="bg-gradient-to-br to-sky-700 from-gray-800 grid grid-cols-2 py-5">
        <div className="flex p-2">
          <h2 className="hover:scale-95 ease-in duration-500 banner-header m-auto tracking-tighter text-center text-gray-100 text-2xl md:text-5xl lg:text-6xl font-medium">
            {TranslateLabel("Crafting")}
            <span> web </span>
            {TranslateLabel("and")}
            <span> mobile</span>
            {IsLanguageEnglish() && " apps"}.
          </h2>
        </div>
        <div className="p-2 flex flex-col">
          <img
            className="rounded-2xl object-cover"
            src="/images/team-work-1-original.jpg"
            alt="team-work"
          />
        </div>
      </section>
      <section className="bg-gray-200 grid grid-cols-2 md:grid-cols-4 text-center py-5 md:py-10 lg:py-15 text-gray-800 px-2">
        <div className="grid grid-cols-1">
          <DevelopmentDetail
            icon={<FaEarthEurope />}
            label="Website development"
          />
        </div>
        <div className="grid grid-cols-1">
          <DevelopmentDetail icon={<FaCodeMerge />} label="API development" />
        </div>
        <div className="grid grid-cols-1">
          <DevelopmentDetail
            icon={<FaMobileScreenButton />}
            label="Mobile first"
          />
        </div>
        <div className="grid grid-cols-1">
          <DevelopmentDetail icon={<FaHandshake />} label="Custom made" />
        </div>
      </section>
      <section className="bg-gradient-to-tr to-sky-700 from-gray-800 grid grid-cols-3">
        <div className="col-span-3 md:col-span-1 flex px-2 py-5">
          <h3 className="hover:scale-95 ease-in duration-500 banner-header m-auto tracking-tighter text-center text-gray-100 text-2xl md:text-3xl lg:text-4xl font-medium">
            <DevelopmentProcessTitle />
          </h3>
        </div>
        <div className="col-span-3 md:col-span-2 p-1 sm:p-2 lg:p-3 pattern-bg-1">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <DevelopmentStep
              icon={<FaRegPaste />}
              title={TranslateLabel("Analysis")}
              description={TranslateLabel(
                "Gather requirements and define project scope. Risks, constraints, and user needs are identified. It establishes the foundation for successful project execution."
              )}
            />
            <DevelopmentStep
              icon={<FaBrain />}
              title={TranslateLabel("Planning")}
              description={TranslateLabel(
                "Strategically outline goals, allocate resources, and set timelines to create an efficient roadmap for project execution. Factor in risks and contingencies."
              )}
            />
            <DevelopmentStep
              icon={<FaHammer />}
              title={TranslateLabel("Design")}
              description={TranslateLabel(
                "Develop a comprehensive blueprint for the system or product, detailing its structure, features, and functionality. Focus on scalability, usability, and user experience."
              )}
            />
            <DevelopmentStep
              icon={<FaCubes />}
              title={TranslateLabel("Implementation")}
              description={TranslateLabel(
                "Transform the design into a functional system, writing code, and integrating components to bring the project to life. Ensure adherence to coding standards and best practices."
              )}
            />
            <DevelopmentStep
              icon={<FaBinoculars />}
              title={TranslateLabel("Testing")}
              description={TranslateLabel(
                "Evaluate the system's functionality, detecting and addressing issues to ensure a robust and error-free product. Conduct thorough testing, including unit, integration, and user acceptance testing."
              )}
            />
            <DevelopmentStep
              icon={<FaRocket />}
              title={TranslateLabel("Maintenance")}
              description={TranslateLabel(
                "Release the product to users and provide ongoing support, addressing issues, and implementing updates for continued optimal performance. Monitor system health and user feedback for continuous improvement."
              )}
            />
          </div>
        </div>
      </section>
      <section className="bg-gray-200 py-10 grid grid-cols-1">
        <div className="flex pb-5 px-1">
          <h2 className="m-auto font-semibold tracking-tighter text-center text-gray-800 text-xl md:text-3xl lg:text-4xl">
            {TranslateLabel("Get a free quote for your new project")}
          </h2>
        </div>
        <div className="m-auto p-2">
          <BlueButton label={"Send us a message"} to={"/contact"} />
        </div>
      </section>
    </div>
  );
}
