import { TranslateLabel } from "../../labels/TranslateLabel";

export default function Footer() {
  return (
    <footer className="bg-white shadow dark:bg-gray-800 bottom-0">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-6">
        <span className="block text-sm text-gray-500 text-center dark:text-gray-400">
          © {new Date().getYear() + 1900} Wonder Conclusion, Lda.{" "}
          {TranslateLabel("All rights reserved")}.
        </span>
      </div>
    </footer>
  );
}
