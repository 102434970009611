import pick from "lodash.pick";
import { useState } from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa6";
import ButtonMailTo from "../../components/button-mail-to/ButtonMailTo";
import Map from "../../components/map/Map";
import config from "../../config";
import { TranslateLabel } from "../../labels/TranslateLabel";

function getEmailBody(inputs) {
  const identityProperties = pick(inputs, [
    "name",
    "email",
    "phone",
    "address",
  ]);
  const identityPropertiesWithData = Object.keys(identityProperties).filter(
    (prop) => inputs[prop] != null && inputs[prop].length > 0
  );
  return `${inputs.message || ""}\n\n${identityPropertiesWithData
    .map((prop) => inputs[prop])
    .join("\n")}`;
}

function ContactForm() {
  const commonInputClasses =
    "hover:scale-95 ease-in duration-500 px-2 w-full text-sm bg-gray-800";
  const textAreaInputClasses = `${commonInputClasses} pt-3 min-h-20`;
  const inputClasses = `${commonInputClasses} py-3`;

  const [inputs, setInputs] = useState({});

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className="space-y-4 mt-8">
        <input
          type="text"
          placeholder={TranslateLabel("Name")}
          name="name"
          className={inputClasses}
          value={inputs.name || ""}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder={TranslateLabel("Address")}
          name="address"
          className={inputClasses}
          value={inputs.address || ""}
          onChange={handleChange}
        />
        <input
          type="text"
          name="phone"
          placeholder={TranslateLabel("Phone number")}
          className={inputClasses}
          value={inputs.phone || ""}
          onChange={handleChange}
        />
        <input
          type="email"
          placeholder={TranslateLabel("Email")}
          name="email"
          className={inputClasses}
          value={inputs.email || ""}
          onChange={handleChange}
        />
        <textarea
          placeholder={TranslateLabel("Write a message here")}
          name="message"
          className={textAreaInputClasses}
          value={inputs.message || ""}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="flex justify-center">
        <ButtonMailTo
          label={TranslateLabel("Send email")}
          className="transition duration-700 ease-in-out mt-8 flex items-center justify-center text-sm rounded px-4 py-2.5 font-semibold bg-gray-800 text-white hover:bg-[#e2d705] hover:text-gray-800 min-w-52"
          body={getEmailBody(inputs)}
          subject="Information request"
        />
      </div>
    </form>
  );
}

export default function Contact() {
  return (
    <>
      <div className="grid md:grid-cols-2 gap-16 items-center relative overflow-hidden py-5 px-3 max-w-6xl mx-auto text-white my-5 sm:my-20 before:absolute before:right-0 before:w-[300px]before:h-full max-md:before:hidden">
        <div>
          <h2 className="text-4xl font-medium">
            {TranslateLabel("Get In Touch")}
          </h2>
          <p className="text-sm text-gray-400 mt-3">
            {TranslateLabel("Have a specific inquiry or want to know more")}?
          </p>
          <ContactForm />
          <ul className="mt-4 flex justify-center lg:space-x-6 max-lg:flex-col max-lg:items-center max-lg:space-y-2 ">
            <li className="flex items-center">
              <FaEnvelope />
              <strong className="pl-1">{config.email}</strong>
            </li>
            <li className="flex items-center text-current">
              <FaPhone />
              <strong className="pl-1">{config.phone}</strong>
            </li>
          </ul>
        </div>
        <div className="z-10 relative h-full max-md:min-h-[350px]">
          <Map />
        </div>
      </div>
    </>
  );
}
