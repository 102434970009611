import { createContext } from "react";

export const LANGUAGES = {
  en: "en",
  fr: "fr",
  pt: "pt",
};

const LANGUAGE_FLAGS = {
  en: "🇺🇸",
  fr: "🇫🇷",
  pt: "🇵🇹",
};

export function nextLanguage(currentLang) {
  const languageKeys = Object.keys(LANGUAGES);
  const currentIndex = languageKeys.indexOf(currentLang);
  const nextIndex = (currentIndex + 1) % languageKeys.length;
  return languageKeys[nextIndex];
}

export function getFlagByLanguage(language) {
  return LANGUAGE_FLAGS[language];
}

export const LanguageContext = createContext(LANGUAGES.en);
