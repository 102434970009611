import { Link } from "react-router-dom";

export function Logo(props) {
  return (
    <Link to={"/"} key={"back-home"}>
      <div className="flex flex-shrink-0 items-center">
        <img
          className="h-5 sm:h-6 md:h-7 w-auto"
          src="/images/wonder_conclusion_lda_logo_2_zoom_white_512.png"
          alt="logo"
          onClick={async () => {
            props.close();
          }}
        />
      </div>
    </Link>
  );
}
