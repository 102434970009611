import isNil from "lodash.isnil";
import { useContext } from "react";
import { LanguageContext, LANGUAGES } from "../context/LanguageContext";
import LABELS from "./labels";

function getLabel(language, label) {
  if (!label || !language) return "";
  return !isNil(LABELS[label]) && !isNil(LABELS[label][language])
    ? LABELS[label][language]
    : label;
}

export function TranslateLabel(label) {
  const { language } = useContext(LanguageContext);
  return getLabel(language, label);
}

export function TranslateLabelByWord(sentence) {
  const { language } = useContext(LanguageContext);
  return sentence
    .split(" ")
    .map((e) => getLabel(language, e))
    .join(" ");
}

export function IsLanguageEnglish() {
  const { language } = useContext(LanguageContext);
  return language === LANGUAGES.en;
}

export function IsLanguageFrench() {
  const { language } = useContext(LanguageContext);
  return language === LANGUAGES.fr;
}

export function IsLanguagePortuguese() {
  const { language } = useContext(LanguageContext);
  return language === LANGUAGES.pt;
}
